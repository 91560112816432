//
// _two-column.scss
// 

[data-layout="twocolumn"] {
    .app-menu {
        padding-bottom: 0;
        width: $twocolumn-menu-width;
        left: $twocolumn-menu-iconview-width;

        .menu-link {
            letter-spacing: .05em;
            cursor: default;
            font-size: 11px;
            text-transform: uppercase;
            font-weight: $font-weight-semibold;

            &:after {
                display: none !important;
            }

            i {
                display: none;
            }
        }

        .navbar-nav {
            height: calc(100vh - #{$header-height});

            @media (max-width: 767.98px) {
                padding-top: 16px;
            }

            >li:not(.twocolumn-item-show) {
                display: none;
            }

            .twocolumn-item-show {
                >div {
                    display: block !important;
                    height: auto !important;
                }
            }

            >.nav-item {
                >.menu-dropdown {
                    display: block !important;
                    height: auto !important;
                }
            }

            .nav-item {
                .menu-dropdown {
                    .row {
                        margin: 0;

                        .col-lg-4 {
                            width: 100%;
                            padding: 0;
                        }
                    }
                }
            }
        }

        .container-fluid {
            padding: 0;
        }
    }

    .main-content {
        margin-left: calc(#{$twocolumn-menu-width} + #{$twocolumn-menu-iconview-width});

        @media (max-width:767.98px) {
            margin-left: $twocolumn-menu-iconview-width;
        }
    }

    &:is([data-sidebar="dark"], [data-sidebar="gradient"], [data-sidebar="gradient-2"], [data-sidebar="gradient-3"], [data-sidebar="gradient-4"]) {   
        .app-menu {
            .navbar-brand-box {
                .logo-light {
                    display: block;
                }

                .logo-dark {
                    display: none;
                }
            }
        }
    }

    .twocolumn-iconview {
        width: $twocolumn-menu-iconview-width;
        background-color: var(--#{$prefix}twocolumn-menu-iconview-bg);
        height: 100%;
        left: -$twocolumn-menu-iconview-width;
        box-shadow: $twocolumn-menu-box-shadow;
        top: 0;
        position: absolute;
        padding: 0;
        text-align: center;

        li {
            position: relative;
            list-style: none;
            .nav-icon {
                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                }
            }
        }

        .nav-icon {
            width: 42px;
            height: 42px;
            color: var(--#{$prefix}vertical-menu-item-color);
            line-height: 42px;
            z-index: 1;
            font-size: 22px;
            text-align: center;
            border-radius: 3px;
            margin: 5px 0;
            display: inline-flex;
            justify-content: center;
            align-items: center;

            .icon-dual {
                width: 18px;
                color: var(--#{$prefix}vertical-menu-item-color);
                fill: var(--#{$prefix}vertical-menu-item-bg);
            }

            &.active {
                background-color: var(--#{$prefix}vertical-menu-item-active-bg);
                color: var(--#{$prefix}vertical-menu-item-active-color);

                .icon-dual {
                    color: var(--#{$prefix}vertical-menu-item-active-color);
                    fill: var(--#{$prefix}vertical-menu-item-active-bg);
                }
            }
        }
    }

    .menu-title {
        display: none;
    }

    @media (max-width: 991.98px) {
        .logo {

            span.logo-lg {
                display: block;
            }

            span.logo-sm {
                display: none;
            }
        }
    }

    &[data-sidebar="light"] {
        .app-menu {
            .navbar-brand-box {
                .logo-light {
                    display: none;
                }

                .logo-dark {
                    display: block;
                }
            }
        }
    }

    &[data-sidebar="dark"] {
        .app-menu {

            .navbar-brand-box {
                .logo-light {
                    display: block;
                }

                .logo-dark {
                    display: none;
                }
            }
        }
    }

    .twocolumn-panel {
        .app-menu {
            width: 0;
            border-right: 0;

            @media (max-width: 575.98px) {
                display: none;
            }
        }

        .navbar-brand-box,
        .navbar-nav {
            display: none;
        }

        .main-content {
            margin-left: $twocolumn-menu-iconview-width;
        }

        #page-topbar,
        .footer {
            left: $twocolumn-menu-iconview-width;
        }

        @media (max-width: 575.98px) {
            .main-content {
                margin-left: 0;
            }

            #page-topbar,
            .footer {
                left: 0;
            }
        }

    }

    #page-topbar,
    .footer {
        @media (max-width: 767.98px) {
            left: $twocolumn-menu-iconview-width;
        }
    }

    @media (max-width: 575.98px) {
        .main-content {
            margin-left: 0;
        }

        #page-topbar,
        .footer {
            left: 0;
        }
    }
}

[data-layout="twocolumn"] {
    @media (max-width: 767.98px) {
        .app-menu {
            left: 0;
            margin-left: 100%;
        }

        .main-content {
            margin-left: 0 !important;
        }

        #page-topbar,
        .footer {
            left: 0 !important;
        }
    }
}