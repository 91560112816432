

.custom-vector-map {
    svg {
        width: 100%;
        max-height: 100%;
        path {
            fill: var(--#{$prefix}light) !important;
        }
    }
}


